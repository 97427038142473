
import { Component, Vue, Watch } from "vue-property-decorator";
import Collections, {
  account,
  challenge,
  dataProvider,
} from "@/store/modules/collections";
import moment from "moment";

@Component
export default class AccountSelection extends Vue {
  selectedAccount: string | null = null;
  currentTime = moment().valueOf();
  timeStarted: number | null = null;
  timeLimit = 150;
  timerInterval: number | null = null;
  // equals to timestamp on challenge
  checkTimeOnMounted(): void {
    this.timeStarted = moment(
      this.getChallenge?.timestamp,
      "DD-MMM-YYYY HH:mm:ss.SSSZ"
    )
      .toDate()
      .valueOf();
    if (this.getChallenge?.timeout) {
      this.timeLimit = this.getChallenge.timeout / 1000;
    }
  }
  mounted(): void {
    this.checkTimeOnMounted();
    this.startTimer();
  }
  startTimer(): void {
    this.timerInterval = setInterval(
      () => (this.currentTime = moment().valueOf()),
      1000
    );
  }
  setSelectedAccount(id: any): void {
    // console.log("Change", id);
    const account = this.getChallenge?.details.accounts?.find(
      (el) => el.id == this.selectedAccount
    );
    if (account) {
      Collections.setAuthAccount(account);
    } else {
      Collections.setAuthAccount(null);
    }
  }
  async submitAccountSelection(): Promise<void> {
    if (this.selectedAccount != null) {
      return await Collections.submitChallenge({str: this.selectedAccount, successMsg: "Account selected..."});
    }
  }
  onTimesUp(): void {
    if (this.timerInterval != null) {
      clearInterval(this.timerInterval);
    }
    Collections.setAuthMessage("Timed out waiting for user input.");
    Collections.setCurrentComponent("Timeout");
  }
  get getChallenge(): challenge | null {
    return Collections.getChallenge;
  }
  get getSelectedDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }
  get formattedTimeLeft(): string {
    const timeLeft = this.timeLeft;
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    let convertedMin = minutes.toString();
    let convertedSec = seconds.toString();

    if (minutes < 10) {
      convertedMin = `0${minutes}`;
    }

    if (seconds < 10) {
      convertedSec = `0${seconds}`;
    }

    return `${convertedMin}:${convertedSec}`;
  }
  get timeLeft(): number {
    if (this.timeStarted != null) {
      return (
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000)
      );
    }
    return 99;
  }
  @Watch("timeLeft", {
    immediate: true,
  })
  onTimeLeft(val: number): void {
    // console.log("Changing Time :", val);
    if (val <= 0) {
      this.onTimesUp();
    }
  }
}
