import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Controller from "@/views/Controller.vue";
import Fatal from "@/components/Fatal.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:id",
    name: "Controller",
    component: Controller,
    props: true,
  },
  {
    path: "*",
    name: "Fatal",
    component: Fatal,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
