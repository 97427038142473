
import { Component, Vue } from "vue-property-decorator";
import Collections, {
  account,
  auth,
  collection,
  storedConsent,
} from "@/store/modules/collections";

@Component
export default class Success extends Vue {
  loading = false;
  get getCollection(): collection | null {
    return Collections.getCollection;
  }
  get getAccountMask(): null | string[] {
    if (
      this.getCollection == undefined ||
      this.getCollection == null ||
      this.getCollection.consent == undefined ||
      this.getCollection.consent == null ||
      this.getCollection.consent.accountMasks == undefined ||
      this.getCollection.consent.accountMasks == null ||
      this.getCollection.consent.accountMasks.length == 0
    )
      return null;

    return this.getCollection.consent.accountMasks;
  }
  get bankCode(): string {
    if (this.getCollection && this.getCollection != null) {
      if (this.getCollection.dataProvider) {
        const code = this.getCollection.dataProvider.code;
        return code.replace("api", "");
      }
    }
    return "fnb";
  }
  get getAccount(): account | null {
    return this.getAuthenticator.account;
  }
  get getAuthenticator(): auth {
    return Collections.getAuthenticator;
  }
  // async mounted(): Promise<void> {
  //   try {
  //     this.loading = true;
  //     await Collections.loadCollection(this.$route.params.id).catch((err) => {
  //     console.log("Error loading collection: ", err);
  //     return Promise.reject(err);
  //   });
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // }
  redirectToCompanySite(): void {
    window.location.href = "www.finchoice.mobi";
  }
}
