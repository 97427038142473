
import { Component, Vue, Watch } from "vue-property-decorator";
import Collections, {
  challenge,
  dataProvider,
} from "@/store/modules/collections";
import moment from "moment";

@Component
export default class Surephrase extends Vue {
  get validLength(): boolean {
    if (Collections.getChallenge?.details.length) {
      if (Collections.getChallenge?.details.positions) {
        return (
          this.requiredPhrase.length ==
          Collections.getChallenge?.details.positions.length
        );
      }
    }
    return false;
  }
  requiredPhrase = [];
  valid = false;
  currentTime = moment().valueOf();
  timeStarted: number | null = null;
  timeLimit = 150;
  timerInterval: number | null = null;
  // equals to timestamp on challenge
  checkTimeOnMounted(): void {
    this.timeStarted = moment(
      this.getChallenge?.timestamp,
      "DD-MMM-YYYY HH:mm:ss.SSSZ"
    )
      .toDate()
      .valueOf();
    if (this.getChallenge?.timeout) {
      this.timeLimit = this.getChallenge.timeout / 1000;
    }
  }
  get enteredArray(): string {
    let newArr = "";
    for (var val in this.requiredPhrase) {
      if (val != undefined) {
        newArr += this.requiredPhrase[val];
      }
    }
    return newArr;
  }
  async submitSurephrase(): Promise<void> {
    await Collections.submitChallenge({
      str: this.enteredArray,
      successMsg: "Surephrase entered...",
    });
  }
  mounted(): void {
    this.checkTimeOnMounted();
    this.startTimer();
  }
  startTimer(): void {
    this.timerInterval = setInterval(
      () => (this.currentTime = moment().valueOf()),
      1000
    );
  }
  onTimesUp(): void {
    if (this.timerInterval != null) {
      clearInterval(this.timerInterval);
    }
    Collections.pushError(new Error("Timed out waiting for user input."));
    Collections.setAuthMessage("Timed out waiting for user input.");
    Collections.setCurrentComponent("Timeout");
  }
  get getSelectedBank(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }
  get getChallenge(): challenge | null {
    return Collections.getChallenge;
  }
  get formattedTimeLeft(): string {
    const timeLeft = this.timeLeft;
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    let convertedMin = minutes.toString();
    let convertedSec = seconds.toString();

    if (minutes < 10) {
      convertedMin = `0${minutes}`;
    }

    if (seconds < 10) {
      convertedSec = `0${seconds}`;
    }

    return `${convertedMin}:${convertedSec}`;
  }
  get timeLeft(): number {
    if (this.timeStarted != null) {
      return (
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000)
      );
    }
    return 99;
  }
  @Watch("timeLeft", {
    immediate: true,
  })
  onTimeLeft(val: number): void {
    // console.log("Changing Time :", val);
    if (val <= 0) {
      this.onTimesUp();
    }
  }
}
