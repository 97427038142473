import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { axiosInstance } from "@/plugins/axios";
import { configExport } from "@/config/config";
import axios, { AxiosResponse } from "axios";
import { customSleep, messageHandler } from "@/plugins/helpers";

export interface credentialElements {
  type: string;
  description: string;
  name: string;
}
export interface reason {
  id: string;
  reason: string | null;
}
interface brand {
  accessDuration: number;
  accreditationNr: string;
  id: string;
  logoUrl: string;
  name: string;
  primaryColor: string;
  purpose: string;
  tagLine: string;
}

export interface dataProvider {
  active: boolean;
  code: string;
  id: string;
  name: string;
  offline: string;
  primaryLogoUrl: string;
  secondaryLogoUrl: string;
  type: string;
  requiredElements: credentialElements[];
}
export interface storedConsent {
  mandated: boolean;
  id: string;
  expires: string;
  name: string;
  dataProviderName: string;
  dataProviderCode: string;
  complete: true;
  accountMasks: string[];
}
interface company {
  id: string;
  name: string;
  registrationNr: string;
}
interface consent {
  accountMasks?: string[];
  credentials: boolean;
  expires: string;
  id: string;
}
interface service {
  id: string;
  name: string;
  purpose: string;
  recurrence: string;
  sources: source[];
}
interface source {
  type: string;
  window: number;
}
export interface account {
  currency: string;
  id: string;
  mask: string;
  name: string;
}
// export interface otpChallenge extends challenge{
//   details:  {
//     length: number;
//     positions: number[];
//     trustedToken: string;
//     type: string;
//   }
// }
// export interface surephraseChallenge extends challenge{
//   details:  {
//     length: number;
//     positions: number[];
//     trustedToken: string;
//     type: string;
//   }
// }
export interface challenge {
  type: string;
  timestamp: string;
  pauseTime: number;
  timeout: number;
  id: string;
  details: {
    accounts?: account[];
    length?: number;
    positions?: number[];
    trustedToken?: string;
    type?: string;
    image?: string;
  };
}
// export interface accountSelectionChallenge extends challenge{
//   details:  {
//     accounts: account[];
//   };
// }
interface status {
  code: number;
  expose: boolean;
  id: string;
  message: string;
  statusCode: string;
  timeout: number;
  timestamp: string;
}
export interface collection {
  brand: brand;
  company: company;
  consent: consent;
  created: string;
  createdBy: string;
  id: string;
  rememberMe: "on" | "off" | "hidden_off" | "hidden_on";
  milestones?: any[];
  idNumber: string;
  name: string;
  services: service[];
  statuses: status[];
  isReusingConsent: boolean;
  hasProvider: boolean;
  hasCredentials: boolean;
  dataProvider?: dataProvider;
  redirectUrl?: string;
}

export interface appState {
  loading: boolean;
  hasIncorrectDetails: boolean;
  errors: Error[];
  milestones: (milestone_base | milestone_custom | milestone_get)[];
  hasSelectedConsent: boolean;
  hasLoadedCollection: boolean;
  hasPreconsents: boolean;
  hasAttemptedCredentials: boolean;
  loadedDataProviders: boolean;
  initComplete: boolean;
  gaveConsent: boolean;
  currentComponent: string | null;
}
export interface lastAuthStatus {
  timestamp: string;
  code: number;
  message: string;
  challengeId: string;
}
export interface auth {
  component: string;
  lastStatus: lastAuthStatus | null;
  fatal: boolean;
  account: account | null;
  //converted message
  message: string;
  stopPolling: boolean;
  errors: Error[];
}
export interface credentials {
  required: { [x: string]: string };
  rememberMe: boolean;
  captcha: string | null;
  enteredCredentials: boolean;
}

export interface milestone_base {
  tag: string;
}
export interface milestone_get extends milestone_base {
  id: string;
  timestamp: string;
}
export interface milestone_custom extends milestone_get {
  direction: string;
}
@Module({
  dynamic: true,
  namespaced: true,
  name: "Collections",
  store,
})
class Collections extends VuexModule {
  currentCollection: collection | null = null;
  selectedConsent: storedConsent | null = null;
  selectedDataProvider: dataProvider | null = null;
  authenticator: auth = {
    component: "Authenticating",
    lastStatus: null,
    fatal: false,
    account: null,
    message: "Unknown error has occured. Please try again...",
    errors: [],
    stopPolling: false,
  };
  credentials: credentials = {
    required: {},
    rememberMe: true,
    captcha: null,
    enteredCredentials: false,
  };
  challenge: challenge | null = null;
  requiredCaptcha = false;
  consents: storedConsent[] = [];
  dataProviders: dataProvider[] = [];
  loading = false;
  cancel: {
    reasons: reason[];
    selectedReason: reason | null;
  } = {
    reasons: [],
    selectedReason: null,
  };

  initState: appState = {
    hasAttemptedCredentials: false,
    hasIncorrectDetails: false,
    currentComponent: null,
    initComplete: false,
    loadedDataProviders: false,
    loading: false,
    errors: [],
    milestones: [],
    gaveConsent: false,
    hasSelectedConsent: false,
    hasLoadedCollection: false,
    hasPreconsents: false,
  };
  @Mutation
  setCredentials(trust: { inp: string; t: string }) {
    // console.log("Here", trust.inp, trust.t);
    const key = trust.t;
    this.credentials.required[`${key}`] = trust.inp;
    this.credentials.enteredCredentials = true;
  }
  @Mutation
  setRememberMe(bol: boolean) {
    this.credentials.rememberMe = bol;
  }
  @Mutation
  setConsents(col: storedConsent[]) {
    this.consents = col;
  }
  @Mutation
  pushError(col: Error) {
    this.initState.errors.push(col);
  }
  @Mutation
  setAuthComp(comp: string) {
    this.authenticator.component = comp;
  }
  @Mutation
  setAuth(comp: auth) {
    this.authenticator = comp;
  }
  @Mutation
  setAuthAccount(comp: account | null) {
    this.authenticator.account = comp;
  }
  @Mutation
  stopAuthPolling(stat: boolean) {
    this.authenticator.stopPolling = stat;
  }
  @Mutation
  setAuthLastStatus(stat: lastAuthStatus | null) {
    this.authenticator.lastStatus = stat;
  }
  @Mutation
  setCaptcha(cap: string) {
    this.credentials.captcha = cap;
  }
  @Mutation
  setCaptchaRequired(req: boolean) {
    this.requiredCaptcha = req;
  }
  @Mutation
  setSelectedConsent(con: storedConsent): void {
    this.selectedConsent = con;
  }
  @Mutation
  setAttemptedCredentials(has: boolean): void {
    this.initState.hasAttemptedCredentials = has;
  }
  @Mutation
  modifyInitState(obj: appState): void {
    this.initState = obj;
  }
  @Mutation
  setCurrentComponent(comp: string | null): void {
    this.initState.currentComponent = comp;
  }
  // @Action
  // async setApiCurrentMilestone(): Promise<void> { }

  // get getMilestones(): (milestone_base | milestone_custom | milestone_get)[] {
  get getMilestones(): milestone_base[] {
    return this.initState.milestones;
  }
  get getCurrentMilestone(): milestone_base | null {
    if (this.initState.milestones.length > 0) {
      return this.initState.milestones[this.initState.milestones.length - 1];
    }
    return null;
  }
  // get getLatestMilestone(): milestone_base | milestone_custom | milestone_get | null {
  //   if (this.initState.milestones.length > 0) {
  //     return this.initState.milestones[this.initState.milestones.length - 1];
  //   } else {
  //     return null;
  //   }
  // }

  // @Action
  // async checkCM(): Promise<void> {
  //   const res = await axiosInstance.get(`${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/milestones`);
  //   this.setCurrentMilestone(res.data);
  // }
  // @Action
  // async checkCurrentMilestone(): Promise<void> {
  //   let retries = 0;
  //   const maxRetries = 2;
  //   let success = false;

  //   while (retries < maxRetries && !success) {
  //     try {
  //       const res = await this.checkCM();
  //       success = true
  //       return Promise.resolve();
  //     } catch (err) {
  //       this.pushError(err);
  //       this.setCurrentComponent("Timeout");
  //     }
  //   }
  // }
  // @Action
  // async checkPrevMilestone(): Promise<void> { }
  // @Mutation
  // setCurrentMilestone(mile: milestone_base | milestone_custom | milestone_get): void {
  //   this.initState.milestones.push(mile);
  // }
  get getAuthenticator(): auth {
    return this.authenticator;
  }
  get getCredentials(): credentials {
    return this.credentials;
  }
  get getCaptcha(): string | null {
    return this.credentials.captcha;
  }
  get getCaptchaRequired(): boolean {
    return this.requiredCaptcha;
  }
  get getAttemptedCredentials(): boolean {
    return this.initState.hasAttemptedCredentials;
  }
  get getCurrentComponent(): string | null {
    return this.initState.currentComponent;
  }
  get getInitState(): appState {
    return this.initState;
  }
  get getSelectedConsent(): storedConsent | null {
    return this.selectedConsent;
  }
  get getSelectedDataProvider(): dataProvider | null {
    return this.selectedDataProvider;
  }
  @Mutation
  setDataProviders(dps: dataProvider[]) {
    this.dataProviders = dps;
  }
  @Mutation
  setSelectedDataProvider(dp: dataProvider) {
    this.selectedDataProvider = dp;
  }
  @Mutation
  setCollection(col: collection | null) {
    this.currentCollection = col;
  }
  @Action
  async wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  @Action
  async submitConsent(): Promise<void> {
    if (this.getCollection == null) {
      return Promise.resolve();
    }
    try {
      if (this.initState.loading == false) {
        this.modifyInitState({
          ...this.getInitState,
          loading: true,
        });
      }
      const res = await axiosInstance
        .post(
          `${configExport.API_URL_COLLECTION}/${this.getCollection.id}/consents`
        )
        .catch((err) => {
          return Promise.reject(err);
        });

      // console.log("Consent Submitted:  ", res.data);

      return Promise.resolve();
    } catch (err) {
      console.log("Error selecting consent: ", err);
    } finally {
      if (this.initState.loading == true) {
        this.modifyInitState({
          ...this.getInitState,
          loading: false,
        });
      }
    }
  }
  @Mutation
  setSelectedReason(res: reason | null) {
    this.cancel.selectedReason = res;
  }
  get getSelectedReason(): reason | null {
    return this.cancel.selectedReason;
  }
  @Action
  async submitCancellation(): Promise<void> {
    if (this.getCollection == null) {
      return Promise.resolve();
    }
    try {
      const res = await axiosInstance
        .delete(
          `${configExport.API_URL_COLLECTION}/${this.getCollection.id}?reason=${this.getSelectedReason?.id}`
        )
        .catch((err) => {
          return Promise.reject(err);
        });

      console.log("Cancellation Submitted");
      await this.setApiMilestone("Cancelled");
      this.setCurrentComponent("Cancelled");

      return Promise.resolve();
    } catch (err) {
      console.log("Error cancelling: ", err);
      return Promise.reject(err);
    }
  }
  @Action
  async goPageBack(str: string): Promise<void> {
    if (this.initState.milestones.length > 0) {
      const ind = this.initState.milestones.findIndex((e) => e.tag == str);
      if (ind > 0) {
        await this.setApiMilestone(this.initState.milestones[ind - 1].tag);
        this.pushMilestone(this.initState.milestones[ind - 1]);
        this.setCurrentComponent(this.initState.milestones[ind - 1].tag);
      } else {
        // console.log("Index: ", ind);
      }
    }
    return Promise.resolve();
  }
  @Action({ rawError: true })
  async submitCredentials(): Promise<void> {
    if (this.getCollection == null) {
      return Promise.resolve();
    }
    try {
      // console.log("Creds", this.getCredentials.required);
      const body = {
        rememberMe: this.getCredentials.rememberMe,
        ...this.getCredentials.required,
      };
      // console.log("Body ", body);
      if (this.getCaptchaRequired == true) {
        if (this.getCredentials.captcha == null) {
          return Promise.reject("Captcha Required");
        }
      }
      const res = await axiosInstance
        .post(
          `${configExport.API_URL_COLLECTION}/${this.getCollection.id}/credentials`,
          body
        )
        .catch((err) => {
          return Promise.reject(err);
        });
      if (this.getCaptchaRequired == true) {
        console.log("Submit capthca here");
        await this.submitCaptchaChallenge().catch((err) => {
          return Promise.reject(err);
        });
        // this.setChallenge(resCap);
        return Promise.resolve();
      }
      // console.log("Credential Response: ", res.data);
      // go to authenticator

      return Promise.resolve();
    } catch (err) {
      console.log("Error submitting credentials: ", err);
    }
  }
  // @Action
  // async submitSurephraseChallenge(str: string): Promise<void> {
  //   try {
  //     if (this.getCollection == null) {
  //       return Promise.resolve();
  //     }

  //     const body = { response: str };

  //     const res = await axiosInstance
  //       .put(`${configExport.API_URL_COLLECTION}/${this.getCollection.id}/challenges/${this.getChallenge?.id}`, body).catch(err => {
  //         return Promise.reject(err);
  //       });
  //       console.log("Surephrase response: ", res.data);
  //       this.setAuthMessage("Surephrase entered...");
  //       this.setAuthComp("Authenticating");
  //       this.setStopPolling(false);
  //     return Promise.resolve();

  //   } catch (err) {
  //     console.log("Error surephrase challenge: ",this.getChallenge, err);
  //   }
  // }
  @Action({ rawError: true })
  async submitChallenge({
    str,
    successMsg,
  }: {
    str: string;
    successMsg: string;
  }): Promise<void> {
    try {
      if (this.getCollection == null) {
        return Promise.resolve();
      }

      const body = { response: str };

      const res = await axiosInstance
        .put(
          `${configExport.API_URL_COLLECTION}/${this.getCollection.id}/challenges/${this.getChallenge?.id}`,
          body
        )
        .catch((err) => {
          return Promise.reject(err);
        });
      console.log("Challenge response: ", res.data);
      this.setAuthMessage(successMsg);
      this.setAuthComp("Authenticating");
      this.setStopPolling(false);
      return Promise.resolve();
    } catch (err) {
      console.log("Error submitting challenge: ", this.getChallenge, err);
    }
  }
  // @Action
  // async submitAuthChallenge(): Promise<void> {
  //   try {
  //     if (this.getCollection == null) {
  //       return Promise.resolve();
  //     }

  //     const body = { response: this.getAuthenticator.account?.id };

  //     const res = await axiosInstance
  //       .put(`${configExport.API_URL_COLLECTION}/${this.getCollection.id}/challenges/${this.getChallenge?.id}`, body).catch(err => {
  //         return Promise.reject(err);
  //       });
  //       console.log("Challenge response: ", res.data);
  //       this.setAuthLastStatus(null);
  //       this.setAuthMessage("Account selected...");
  //       this.setAuthComp("Authenticating");
  //       this.setStopPolling(false);
  //     return Promise.resolve();

  //   } catch (err) {
  //     console.log("Error AUth challenge: ",this.getChallenge, err);
  //   }
  // }
  @Action({ rawError: true })
  async submitCaptchaChallenge(): Promise<void> {
    try {
      if (this.getCollection == null) {
        return Promise.resolve();
      }
      if (this.credentials.captcha == null) {
        return Promise.reject("Captcha Required");
      }
      if (this.challenge == null) {
        return Promise.reject("Challenge Required for Captcha");
      }
      const body = { response: this.getCaptcha };

      const res = await axiosInstance
        .post(
          `${configExport.API_URL_COLLECTION}/${this.getCollection.id}/challenges/${this.challenge.id}`,
          body
        )
        .catch((err) => {
          return Promise.reject(err);
        });
      return Promise.resolve();
    } catch (err) {
      console.log("Error selecting consent: ", err);
    }
  }
  @Action({ rawError: true })
  async submitPreConsent(item: storedConsent): Promise<void> {
    if (this.getCollection == null) {
      return Promise.resolve();
    }
    try {
      const body = { consentId: item.id };

      const res = await axiosInstance
        .post(
          `${configExport.API_URL_COLLECTION}/${this.getCollection.id}/consents/select`,
          body
        )
        .catch((err) => {
          return Promise.reject(err);
        });

      this.setSelectedConsent(item);
      return Promise.resolve();
    } catch (err) {
      console.log("Error selecting consent: ", err);
    }
  }

  get getServiceText(): string {
    const temp: string[] = [];
    if (!this.getCollection) {
      return "";
    }
    this.getCollection.services.forEach((service) => {
      service.sources.forEach((source) => {
        if (!temp.find((el) => el === source.type.replace(/-/g, " "))) {
          temp.push(source.type.replace(/-/g, " "));
        }
      });
    });

    let str = "";
    if (temp.length == 1) {
      return temp[0];
    } else if (temp.length > 1) {
      for (let i = 0; i < temp.length; i++) {
        if (temp.length > 1 && i == temp.length - 1) {
          str += `&amp; ${temp[i]}`;
        } else {
          str += `${temp[i]},`;
        }
      }
    }
    return str;
  }
  get getCollection(): collection | null {
    return this.currentCollection;
  }
  get getConsents(): storedConsent[] {
    return this.consents;
  }
  get getDataProviders(): dataProvider[] {
    return this.dataProviders;
  }
  @Action({ rawError: true })
  async pollLatestStatus(): Promise<void> {
    while (this.getAuthenticator.stopPolling == false) {
      await customSleep(5000);
      await this.loadLatestStatus().catch((err) => {
        console.log("While polling error: ", err);
      });
    }
  }
  @Action({ rawError: true })
  async callLatestStatus(): Promise<AxiosResponse> {
    return await axiosInstance
      .get(
        `${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/statuses/0`
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  @Action({ rawError: true })
  async loadLatestStatus() {
    let retries = 0;
    const maxRetries = 2;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        //random
        const res = await this.callLatestStatus();
        success = true;
        await this.handleLatestStatus(res);
        return Promise.resolve();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status || 500;
          console.log(`Error Status: ${status}`);
          if (status == 404) {
            await customSleep(200);
            console.log(`Retrying... `);
          } else {
            Promise.reject(err);
          }
        } else {
          // other error
          console.error(`Error latest status: ${err}`);
        }
        success = false;
      }
      retries++;
    }
  }
  @Mutation
  setIncorrectDetails(stat: boolean): void {
    this.initState.hasIncorrectDetails = stat;
  }
  @Action({ rawError: true })
  async handleLatestStatus(res: AxiosResponse): Promise<void> {
    console.log("Handle request top", res.data);
    if (res.data == null) {
      console.log("Data == null");
    }
    if (
      this.getAuthenticator.lastStatus != null &&
      res.data.message === this.getAuthenticator.lastStatus.message &&
      // res.data.code === this.getAuthenticator.lastStatus.code &&
      res.data.challengeId === this.getAuthenticator.lastStatus.challengeId
    ) {
      console.log("Same as previous message");
      return;
    }
    if (res.data.expose == false) {
      console.log("Expose == false");
      return;
    }
    if (this.authenticator.stopPolling == true) {
      console.log("stopPolling == true");
      return;
    }
    this.setAuthLastStatus({
      timestamp: res.data.timestamp,
      code: res.data.code,
      message: res.data.message,
      challengeId: res.data.challengeId,
    });
    console.log("Handle request set status", this.getAuthenticator.lastStatus);
    try {
      console.log(
        "Current Polling Code: ",
        this.getAuthenticator.lastStatus?.code
      );
      switch (this.getAuthenticator.lastStatus?.code) {
        case 2: {
          this.setAuth({
            ...this.getAuthenticator,
            stopPolling: true,
            message: messageHandler(res.data.message),
            fatal: true,
          });
          this.setCurrentComponent("Timeout");
          break;
        }
        case 1099: {
          this.setAuthMessage(res.data.message);
          break;
        }
        case 1010: {
          //CAPTCHA required
          this.setAuthMessage(res.data.message);
          this.setStopPolling(true);
          //go back to credentials here
          this.setIncorrectDetails(false);
          this.setCaptchaRequired(true);
          this.setCurrentComponent("Credentials");
          break;
        }
        case 1030: {
          //Capitec app
          this.setAuthComp("appAuth");
          break;
        }
        case 1020:
        case 1040:
        case 1021:
        case 1031:
        case 1041: {
          this.setAuthMessage(res.data.message);
          await this.determine2FAChallengeType().catch((err) => {
            return Promise.reject(err);
          });
          break;
        }
        case 1110: {
          // An account selected challenge response is required
          this.setAuthMessage(res.data.message);
          this.setStopPolling(true);
          await this.loadChallenge().catch((err) => {
            return Promise.reject(err);
          });
          if (this.challenge?.type == "account-selection") {
            this.setAuthComp("AccountSelection");
            return Promise.resolve();
          }
          break;
        }
        case 1199: {
          if (this.getAuthenticator.component != "Authenticating") {
            this.setAuthComp("Authenticating");
          }
          // An account selected challenge response is required
          this.setAuthMessage(res.data.message);
          break;
        }
        case 1900: {
          // collection timeout
          this.setStopPolling(true);
          //another fnb session is available on this error. What else will call this? Retries automatically
          this.setAuthMessage(res.data.message);
          this.setCurrentComponent("Timeout");
          break;
        }
        case 2000: {
          this.setAuthMessage(res.data.message);
          // collection has completed successfully.
          this.setStopPolling(true);
          await this.setApiMilestone("Success");
          this.setCurrentComponent("Success");
          // milestone and go to success screen
          break;
        }
        case 3000:
        case 5000: {
          // collection timeout
          this.setStopPolling(true);
          this.setAuthMessage(res.data.message);
          this.setCurrentComponent("Timeout");
          break;
        }
        case 4000: {
          // collection cancelled by system
          this.setStopPolling(true);
          this.setAuthMessage(res.data.message);
          break;
        }
        case 9001: {
          this.setStopPolling(true);
          this.setAuthMessage("Successful but no data");
          this.setCurrentComponent("Timeout");
          break;
        }
        case 9000: {
          // code: 9000
          console.log("9000 ", res.data.message);
          this.setAuthMessage(res.data.message);
          if (
            this.getAuthenticator.message.includes("invalid token password")
          ) {
            this.setStopPolling(true);
            this.setAuthComp("OTP");
            // this.setCurrentComponent("OTP");
            break;
          }
          //capitec dashboard message
          if (this.getAuthenticator.message.includes("Choose a dashboard")) {
            break;
          }
          this.setStopPolling(true);
          //capitec dashboard message
          if (
            this.getAuthenticator.message
              .toLowerCase()
              .includes("login error: Incorrect details") ||
            this.getAuthenticator.message
              .toLowerCase()
              .includes("login error: couldn't confirm your details") ||
            this.getAuthenticator.message
              .toLowerCase()
              .includes("invalid user credentials entered") ||
            this.getAuthenticator.message
              .toLowerCase()
              .includes("incorrect sign-in details") ||
            this.getAuthenticator.message
              .toLowerCase()
              .includes("invalid credentials") ||
            this.getAuthenticator.message
              .toLowerCase()
              .includes(
                "login error: your login details have been entered incorrectly"
              )
          ) {
            this.setAttemptedCredentials(true);
            this.setIncorrectDetails(true);
            this.setCurrentComponent("Credentials");
            // go back to credentials
            break;
          } else {
            this.stopAuthPolling(true);
            this.setCurrentComponent("Timeout");
            this.pushError(res.data);
          }
          break;
        }
        default: {
          // TODO - remove this...
          console.log("Switch default on status code");
          this.setAuthMessage(res.data.message);
        }
      }
    } catch (err) {
      // do something with the error
      console.log("Errors? ", err);
      this.setAuthMessage(String(err));
      this.setCurrentComponent("Timeout");
    }
    console.log("Handle request end", this.getAuthenticator.lastStatus);
    return Promise.resolve();
  }
  @Mutation
  setAuthMessage(msg: string) {
    this.authenticator.message = messageHandler(msg);
  }
  @Mutation
  setStopPolling(stat: boolean) {
    this.authenticator.stopPolling = stat;
  }
  @Action({ rawError: true })
  async loadDataProviders(id: string): Promise<void> {
    let retries = 0;
    const maxRetries = 2;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        await this.callDataProviders();
        success = true;
        return Promise.resolve();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status || 500;
          console.log(`Error Status: ${status}`);
          if (status == 404) {
            await customSleep(200);
            console.log(`Retrying... `);
          } else {
            Promise.reject(err);
          }
        } else {
          // other error
          console.error(`Error Consents: ${err}`);
        }
      }
      retries++;
    }
  }
  @Action({ rawError: true })
  async callDataProviders(): Promise<void> {
    try {
      const res = await axiosInstance
        .get(
          `${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/data-providers`
        )
        .catch((err) => {
          return Promise.reject(err);
        });

      // console.log("Received banks: ", res.data);
      this.setDataProviders(res.data);
      return Promise.resolve();
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
  @Action({ rawError: true })
  async loadConsents(id: string): Promise<void> {
    let retries = 0;
    const maxRetries = 2;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        await this.callConsents();
        success = true;
        return Promise.resolve();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status || 500;
          console.log(`Error Status: ${status}`);
          if (status == 404) {
            await customSleep(200);
            console.log(`Retrying... `);
          } else {
            Promise.reject(err);
          }
        } else {
          // other error
          console.error(`Error Consents: ${err}`);
        }
      }
      retries++;
    }
  }
  @Mutation
  pushMilestone(mil: milestone_base) {
    this.initState.milestones.push(mil);
  }
  @Action({ rawError: true })
  async callConsents(): Promise<void> {
    const res = await axiosInstance
      .get(
        `${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/consents`
      )
      .catch((err) => {
        return Promise.reject(err);
      });

    console.log("Received consents: ", res.data);
    this.setConsents(res.data);
    return Promise.resolve();
  }
  @Action({ rawError: true })
  async callCollection(id: string): Promise<void> {
    const res = await axiosInstance
      .get(`${configExport.API_URL_COLLECTION}/${id}`)
      .catch((err) => {
        return Promise.reject(err);
      });

    console.log("Received collection: ", res.data);
    this.setCollection(res.data);

    if (this.getCollection) {
      if (this.getCollection.milestones) {
        this.getCollection.milestones.forEach((element) => {
          this.pushMilestone(element);
        });
      }
      if (this.getCollection.dataProvider) {
        this.setSelectedDataProvider(this.getCollection.dataProvider);
      }
    }
    return Promise.resolve();
  }
  @Action({ rawError: true })
  async callApiCancelReasons(): Promise<void> {
    const res = await axiosInstance
      .get(configExport.API_URL_REASONS)
      .catch((err) => {
        return Promise.reject(err);
      });
    // console.log("Reasons: ", res.data);
    this.setReasons(res.data);

    return Promise.resolve();
  }
  @Mutation
  setReasons(res: reason[]) {
    this.cancel.reasons = res;
  }
  get getReasons(): reason[] {
    return this.cancel.reasons;
  }

  @Action({ rawError: true })
  async callSetMilestone(tag: string): Promise<void> {
    const res = await axiosInstance
      .post(
        `${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/milestones`,
        {
          tag: tag,
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
    return Promise.resolve();
  }
  @Action({ rawError: true })
  async setApiMilestone(tag: string): Promise<void> {
    let retries = 0;
    const maxRetries = 2;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        await this.callSetMilestone(tag);
        this.pushMilestone({ tag: tag } as milestone_base);
        success = true;
        return Promise.resolve();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status || 500;
          console.log(`Error Status: ${status}`);
          if (status == 404) {
            await customSleep(200);
            console.log(`Retrying... `);
          } else {
            Promise.reject(err);
          }
        } else {
          // other error
          console.error(`Error Setting Milestone: ${err}`);
        }
      }
      retries++;
    }
  }

  get getChallenge(): challenge | null {
    return this.challenge;
  }
  @Action({ rawError: true })
  async callChallenge(): Promise<void> {
    const res = await axiosInstance
      .get(
        `${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/challenges/${this.getAuthenticator.lastStatus?.challengeId}`
      )
      .catch((err) => {
        return Promise.reject(err);
      });
    this.setChallenge(res.data);
    return Promise.resolve();
  }
  @Action({ rawError: true })
  async loadChallenge(): Promise<void> {
    let retries = 0;
    const maxRetries = 2;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        await this.callChallenge();
        const challenge = this.getChallenge;
        if (challenge == null) {
          return Promise.reject("Challenge data is empty after call");
        }
        success = true;
        return Promise.resolve();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status || 500;
          console.log(`Error Status: ${status}`);
          if (status == 404) {
            await customSleep(200);
            console.log(`Retrying... `);
          } else {
            Promise.reject(err);
          }
        } else {
          // other error
          console.error(`Error Challenge: ${err}`);
        }
      }
      retries++;
    }
  }
  @Mutation
  setChallenge(challenge: challenge): void {
    this.challenge = challenge;
  }
  @Action({ rawError: true })
  async loadCollection(id: string): Promise<void> {
    let retries = 0;
    const maxRetries = 2;
    let success = false;

    while (retries < maxRetries && !success) {
      try {
        await this.callCollection(id);
        success = true;
        return Promise.resolve();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const status = err.response?.status || 500;
          console.log(`Error Status: ${status}`);
          if (status == 404) {
            await customSleep(200);
            console.log(`Retrying ${id}... `);
          } else {
            console.log(`Rejecting here ${id}... `);
            Promise.reject(err);
          }
        } else {
          // other error
          console.error(`Error Collection: ${err}`);
        }
      }
      retries++;
    }
  }
  @Action({ rawError: true })
  async determine2FAChallengeType(): Promise<void> {
    const res = await axiosInstance
      .get(
        `${configExport.API_URL_COLLECTION}/${this.getCollection?.id}/challenges/${this.getAuthenticator.lastStatus?.challengeId}`
      )
      .catch((err) => {
        return Promise.reject(err);
      });
    // console.log("Challenge ", res)
    try {
      switch (res.data.type) {
        case "otp": {
          this.setStopPolling(true);
          this.setChallenge(res.data);
          // set challenge here
          this.setAuthComp("OTP");
          break;
        }
        case "captcha": {
          break;
        }
        case "token": {
          break;
        }
        case "password": {
          //absa and
          this.setStopPolling(true);
          // set challenge here
          this.setChallenge(res.data);
          this.setAuthComp("Surephrase");
          break;
        }
        case "qrcode": {
          //absa and
          this.setStopPolling(false);
          // set challenge here
          this.setChallenge(res.data);
          this.setAuthComp("QRCode");
          break;
        }
      }
    } catch (err) {
      this.setAuthMessage(JSON.stringify(err));
      this.setCurrentComponent("Timeout");
    }
  }
}

export default getModule(Collections);
