
import { Component, Vue } from "vue-property-decorator";
import Collections, { auth, dataProvider } from "@/store/modules/collections";

@Component
export default class Authenticating extends Vue {
  defaultMsg = "Credentials Submitted";
  get getAuthenticator(): auth {
    return Collections.getAuthenticator;
  }
  get getSelectedDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }
  get statusMsg(): string {
    if (this.getAuthenticator.lastStatus == null) {
      return this.defaultMsg;
    } else {
      switch (this.getAuthenticator.message) {
        case "Authorization completed": {
          return "Account selection confirmed";
        }
        case "ACCOUNT_VERIFICATION collected.": {
          return "Data collection in progress";
        }
        default: {
          return this.getAuthenticator.message;
        }
      }
    }
  }
  requestCancel(): void {
    Collections.setStopPolling(true);
    Collections.setCurrentComponent("Cancel");
  }
  mounted(): void {
    Collections.pollLatestStatus();
  }
}
