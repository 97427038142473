
import { Component, Vue, Watch } from "vue-property-decorator";
import Collections, { collection } from "@/store/modules/collections";
import Fatal from "@/components/Fatal.vue";
import { Route } from "vue-router";
//random line
@Component({
  components: {
    Fatal,
  },
})
export default class App extends Vue {
  async mounted(): Promise<void> {
    await this.setupApp();
    this.setColors();
  }
  setColors(): void {
    this.$vuetify.theme.currentTheme.primary =
      Collections.getCollection?.brand.primaryColor;
  }
  async setupApp(): Promise<void> {
    if (this.$route.params.id != null) {
      try {
        Collections.modifyInitState({
          ...Collections.getInitState,
          loading: true,
        });
        Collections.setCollection(null);
        await Collections.loadCollection(this.$route.params.id).catch((err) => {
          console.log("Error loading collection: ", err);
          return Promise.reject(err);
        });
        if (Collections.getCollection != null) {
          Collections.modifyInitState({
            ...Collections.getInitState,
            hasLoadedCollection: true,
          });
          //change color
          document
            .querySelector("body")
            ?.style.setProperty(
              "--brand-color",
              Collections.getCollection?.brand.primaryColor
            );
        }
        if (Collections.getInitState.hasLoadedCollection == true) {
          //load consents
          await Collections.loadConsents(this.$route.params.id).catch((err) => {
            console.log("Error loading consents: ", err);
            return Promise.reject(err);
          });
          if (Collections.getConsents.length > 0) {
            Collections.modifyInitState({
              ...Collections.getInitState,
              hasPreconsents: true,
            });
          }
          await Collections.loadDataProviders(this.$route.params.id).catch(
            (err) => {
              console.log("Error loading banks: ", err);
              return Promise.reject(err);
            }
          );
          if (Collections.getDataProviders.length > 0) {
            Collections.modifyInitState({
              ...Collections.getInitState,
              loadedDataProviders: true,
            });
          }
          let nextComponent = "Consent"; // default

          // check milestones here on collection.
          if (Collections.getMilestones.length > 0) {
            if (Collections.getCurrentMilestone)
              nextComponent = Collections.getCurrentMilestone.tag;
          } else {
            // if no milestones [] => call setMilestone for
            await Collections.setApiMilestone(nextComponent);
            Collections.pushMilestone({
              tag: nextComponent,
            });
          }

          console.log("Carry on");
          Collections.modifyInitState({
            ...Collections.getInitState,
            initComplete: true,
          });

          //manual app routing here. Decide components
          // only need to get the initial page correct. The rest will handle the rest
          // if (Collections.getInitState.hasPreconsents) {
          //   Collections.setCurrentComponent("PreConsent");
          // } else {
          //   console.log("Other :");
          //   Collections.setCurrentComponent("Consent");
          //   // Collections.setCurrentComponent("Consent");
          // }
          Collections.setCurrentComponent(nextComponent);
        } else {
          console.error("Issue with the collection");
          Collections.setAuthMessage("Error loading collection...");
          Collections.setCurrentComponent("Timeout");
          //send error to vuex
          //go to error page
        }
      } catch (err) {
        console.log("Error with initialization...", err);
        //send error to vuex
        //push to error page
        if (err instanceof Error) {
          Collections.pushError(err);
        }
        Collections.setCurrentComponent("Timeout");
        //
      } finally {
        Collections.modifyInitState({
          ...Collections.getInitState,
          loading: false,
        });
      }
    }
  }
  get getCollection(): collection | null {
    return Collections.getCollection;
  }
  get getCurrentComponent(): string | null {
    return Collections.getCurrentComponent;
  }
  @Watch("getCurrentComponent", {
    immediate: true,
  })
  onComponentChange(val: string | null, old: string | null): void {
    // console.log("Changing Time :", val);
    if (val && val == "Success" && old && old != "Success") {
      console.log("Watcher for component reload success", val, old);
      this.setupApp();
    }
  }
}
