
import { Component, Vue } from "vue-property-decorator";
import Collections, {
  dataProvider,
  credentials,
  challenge,
} from "@/store/modules/collections";

@Component
export default class Credentials extends Vue {
  goPageBack(): void {
    // console.log("Go page back");
    Collections.goPageBack("Credentials");
  }
  valid = false;
  credentials: {
    required: { [x: string]: string };
    rememberMe: boolean;
    captcha: string | null;
    reveal: boolean;
  } = {
    required: {},
    rememberMe: true,
    captcha: null,
    // username: "",
    reveal: false,

    // password: "",
    // captcha: "",
  };
  rules = {
    required: (value: string): boolean | string => !!value || "Required.",
    min: (v: string): boolean | string => v.length >= 8 || "Min 8 characters",
    numbers: (v: string): boolean | string =>
      /^[0-9]/.test(v) || "Numbers only",
    email: [
      (v: string): boolean | string => !!v || "E-mail is required",
      (v: string): boolean | string =>
        /.+@.+/.test(v) || "E-mail must be valid",
      (v: string): boolean | string =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(v).toLowerCase()
        ) || "E-mail must be valid",
    ],
  };
  get getRememberMeStatus(): boolean {
    if (Collections.currentCollection) {
      switch (Collections.currentCollection.rememberMe) {
        case "hidden_on":
        case "hidden_off":
          return false;
        default:
          return true;
      }
    } else return false;
  }
  get getHasEnteredCredentials(): boolean {
    return Collections.getAttemptedCredentials;
  }
  get getIncorrectDetails(): boolean {
    return Collections.initState.hasIncorrectDetails;
  }
  get getDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }
  get getChallenge(): challenge | null {
    return Collections.getChallenge;
  }
  get getCredentials(): credentials {
    return Collections.getCredentials;
  }
  get bankName(): string {
    if (this.getDataProvider == null) {
      return "";
    }
    if (this.getDataProvider.code == "fnb") {
      return this.getDataProvider.code.toUpperCase();
    } else {
      return this.getDataProvider.name;
    }
  }
  get getCaptchaRequired() {
    return Collections.getCaptchaRequired;
  }
  async mounted(): Promise<void> {
    if (Collections.currentCollection != null) {
      switch (Collections.currentCollection.rememberMe) {
        case "on":
        case "hidden_on":
          this.credentials.rememberMe = true;
          break;
        case "off":
        case "hidden_off":
          this.credentials.rememberMe = false;
          break;
      }
    }
    if (this.getCaptchaRequired == true) {
      await Collections.callChallenge();
    }
  }
  updateCaptcha(str: string | null) {
    if (str == null) Collections.setCaptcha("");
    else Collections.setCaptcha(str);
  }
  setRememberMe(e: boolean): void {
    // console.log("Remember me change: ", e);
    Collections.setRememberMe(e);
  }
  updateValue(inp: string, t: string): void {
    // console.log("Updated: ", inp, t);
    Collections.setCredentials({ inp: inp, t: t });
  }

  async submitCredentials(): Promise<void> {
    await Collections.submitCredentials().catch((err) => {
      return Promise.reject(err);
    });
    Collections.setAuthMessage("Credentials Submitted...");
    Collections.setStopPolling(false);
    Collections.setCurrentComponent("Authenticator");
  }
}
