const baseUrl = "https://api.truidconnect.io/consumer-api";

export default {
  API_URL_REASONS: `${baseUrl}/reasons`,
  API_KEY: "e40159d272874bdb815d6f8bdb6dae17",
  API_URL_COLLECTION: `${baseUrl}/collections`,
  API_URL_CONSENTS: `${baseUrl}/consents`,
  API_URL_BANKS: `${baseUrl}/banks`,

  APP_STATE: {
    LOADED: "LOADED",
    LOADING: "LOADING",
    FAILED: "FAILED",
    NULL: "NULL",
  },
};
