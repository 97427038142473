
import { Component, Vue } from "vue-property-decorator";
import Collections, { reason } from "@/store/modules/collections";

@Component
export default class Cancel extends Vue {
  selectedReason: reason | null = null;
  async mounted(): Promise<void> {
    await Collections.callApiCancelReasons();
  }
  get getSelectedReason(): reason | null {
    return Collections.getSelectedReason;
  }
  setSelectedReason(res: reason | null): void {
    Collections.setSelectedReason(res);
  }
  get getReasons(): reason[] {
    return Collections.getReasons;
  }
  get getNotNullReasons(): reason[] {
    return this.getReasons.filter((e) => e.reason != null);
  }

  async submitCancellation(): Promise<void> {
    return await Collections.submitCancellation();
  }
  toCurrentMilestone(): void {
    if (Collections.getMilestones.length > 0)
      Collections.setCurrentComponent(
        Collections.getMilestones[Collections.getMilestones.length - 1].tag
      );
  }
}
