
import { Component, Vue, Watch } from "vue-property-decorator";
import Collections, {
  challenge,
  dataProvider,
} from "@/store/modules/collections";
import moment from "moment";
@Component
export default class QRCode extends Vue {
  learnMore = false;
  qrCode = null;
  timeLimit = 150;
  timerInterval: number | null = null;
  timeStarted: number | null = null;
  currentTime = moment().valueOf();

  mounted() {
    this.startTimer();
  }
  // equals to timestamp on challenge
  cancel() {
    this.$emit("cancel");
  }
  startTimer() {
    this.timeStarted = moment().valueOf();
    this.timerInterval = setInterval(
      () => (this.currentTime = moment().valueOf()),
      1000
    );
  }
  onTimesUp() {
    if (this.timerInterval != null) {
      clearInterval(this.timerInterval);
    }
    Collections.setAuthMessage("Timed out waiting for user input.");
    Collections.setCurrentComponent("Timeout");
  }
  get getDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }

  get getChallenge(): challenge | null {
    return Collections.getChallenge;
  }

  get getSelectedDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }

  get formattedTimeLeft(): string {
    const timeLeft = this.timeLeft;
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    let convertedMin = minutes.toString();
    let convertedSec = seconds.toString();

    if (minutes < 10) {
      convertedMin = `0${minutes}`;
    }

    if (seconds < 10) {
      convertedSec = `0${seconds}`;
    }

    return `${convertedMin}:${convertedSec}`;
  }

  get timeLeft(): number {
    if (this.timeStarted != null) {
      const time =
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000);
      if (time <= 0) {
        return 0;
      } else {
        return time;
      }
    }
    return 99;
  }
  @Watch("timeLeft", {
    immediate: true,
  })
  onTimeLeft(val: number): void {
    // console.log("Changing Time :", val);
    if (val <= 0) {
      this.onTimesUp();
    }
  }
}
