import _Vue from "vue";

const env: string = process.env.VUE_APP_MODE || "development";

interface configsModel {
  [key: string]: config;
}
interface config {
  API_URL_REASONS: string;
  API_KEY: string;
  API_URL_COLLECTION: string;
  API_URL_CONSENTS: string;
  API_URL_BANKS: string;
  APP_STATE: {
    [key: string]: string;
  };
}

import dev from "./development";
import production from "./production";
import staging from "./staging";

const configs: configsModel = {
  development: dev,
  production: production,
  staging: staging,
};

const envConfig: config = configs[env];
//for plugins
//for vue.use
export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$config = configs[env];
  },
};
const configExport = envConfig;
export {
  configExport,
  config,
}

