export const messageHandler = (messageIn: string) => {
  if(messageIn.includes("Authorization completed")){
    return "Account selection confirmed";
  } else if(messageIn.includes("ACCOUNT_VERIFICATION collected.")) {
      return "Data collection in progress";
  } else if(messageIn.includes("Build info") || messageIn.includes("Driver info") || messageIn.includes("System info")) {
      return "Whoops, something went wrong, please retry.";
  } else {
    return messageIn;
  }
}
export const customSleep = async function(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}