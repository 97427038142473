
import { Component, Vue, Watch } from "vue-property-decorator";
import Collections, {
  challenge,
  dataProvider,
} from "@/store/modules/collections";
import moment from "moment";

@Component
export default class AppAuth extends Vue {
  timeStarted: number | null = null;
  timeLimit = 150;
  currentTime = moment().valueOf();
  timerInterval: number | null = null;
  get getTimestamp(): string | null {
    if (Collections.getAuthenticator.lastStatus)
      return Collections.getAuthenticator.lastStatus.timestamp;
    else return moment().valueOf().toString();
  }

  checkTimeOnMounted(): void {
    this.timeStarted = moment(this.getTimestamp, "DD-MMM-YYYY HH:mm:ss.SSSZ")
      .toDate()
      .valueOf();
    //default timeout. Since there is no challenge id
    this.timeLimit = 150;
  }
  mounted(): void {
    this.checkTimeOnMounted();
    this.startTimer();
  }
  startTimer(): void {
    this.timerInterval = setInterval(
      () => (this.currentTime = moment().valueOf()),
      1000
    );
  }
  onTimesUp(): void {
    if (this.timerInterval != null) {
      clearInterval(this.timerInterval);
    }
    Collections.pushError(new Error("Timed out waiting for user input."));
    Collections.setAuthMessage("Timed out waiting for user input.");
    Collections.setCurrentComponent("Timeout");
  }
  get getSelectedDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }

  get formattedTimeLeft(): string {
    const timeLeft = this.timeLeft;
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    let convertedMin = minutes.toString();
    let convertedSec = seconds.toString();

    if (minutes < 10) {
      convertedMin = `0${minutes}`;
    }

    if (seconds < 10) {
      convertedSec = `0${seconds}`;
    }

    return `${convertedMin}:${convertedSec}`;
  }
  get timeLeft(): number {
    if (this.timeStarted != null) {
      return (
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000)
      );
    }
    return 99;
  }
  @Watch("timeLeft", {
    immediate: true,
  })
  onTimeLeft(val: number): void {
    // console.log("Changing Time :", val);
    if (val <= 0) {
      this.onTimesUp();
    }
  }

  cancelPolling(): void {
    Collections.setCurrentComponent("Cancel");
  }
}
