
import { Component, Vue } from "vue-property-decorator";
import Collections, {
  collection,
  milestone_base,
} from "@/store/modules/collections";

@Component
export default class Timeout extends Vue {
  get displayMessage(): string {
    return Collections.getAuthenticator.message;
  }
  get getLastMilestone(): milestone_base | null {
    return Collections.getCurrentMilestone;
  }
  get changeDependingMessage(): boolean {
    if (this.displayMessage.includes("pre-selected accounts are not available") == true) {
      return true;
    } else {
      return false;
    }
  }
  redirectToFinchoice(): void {
    window.location.href = "https://www.finchoice.mobi/Documents/DocUploader";
  }
  retry(): void {
    if (this.getLastMilestone != null) {
      Collections.setCurrentComponent(this.getLastMilestone.tag);
    }
  }
}
