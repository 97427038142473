
import { Component, Vue } from "vue-property-decorator";
// import Welcome from "@/components/Welcome.vue";
import Consent from "@/components/Consent.vue";
import PreConsent from "@/components/PreConsent.vue";
import Credentials from "@/components/Credentials.vue";
import Authenticator from "@/components/Authenticator.vue";
import Success from "@/components/Success.vue";
import Timeout from "@/components/Timeout.vue";
import Cancel from "@/components/Cancel.vue";
import Cancelled from "@/components/Cancelled.vue";

import Collections, {
  appState,
  storedConsent,
} from "@/store/modules/collections";

@Component({
  components: {
    Authenticator,
    Consent,
    PreConsent,
    Credentials,
    Success,
    Timeout,
    Cancel,
    Cancelled,
  },
})
export default class Controller extends Vue {
  changeComponent(str: string): void {
    console.log("Change componetnt: ", str);
  }
  selectedConsentEvent(con: storedConsent): void {
    console.log("Con selected ", con);
  }
  get getCurrentComponent(): string {
    if (Collections.getCurrentComponent != null)
      return Collections.getCurrentComponent;
    else return "";
  }
  get getConsents(): storedConsent[] {
    return Collections.getConsents;
  }
  get getSelectedConsent(): storedConsent | null {
    return Collections.getSelectedConsent;
  }
  get getInitState(): appState {
    return Collections.getInitState;
  }
}
