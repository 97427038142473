
import { Component, Vue } from "vue-property-decorator";
import Collections, { collection } from "@/store/modules/collections";

@Component
export default class Consent extends Vue {
  //random comment 
  moreInfoModal = false;
  learnMoreModal = false;
  tcs = false;
  items = ["To calculate your affordability", "To verify your income"];

  requestCancel(): void {
    Collections.setCurrentComponent("Cancel");
  }
  get bankCode(): string {
    if (this.getCollection != null) {
      if (this.getCollection.dataProvider) {
        const code = this.getCollection.dataProvider.code;
        return code.replace("api", "");
      }
    }
    return "fnb";
  }
  get getCollection(): collection | null {
    return Collections.getCollection;
  }
  get getName(): string | undefined {
    return this.getCollection?.name.split(" ")[0];
  }
  get getServices(): string {
    return Collections.getServiceText;
  }

  async submitConsent(): Promise<void> {
    await Collections.submitConsent().catch((err) => {
      // console.log("Submit consent error: ", err);
      return Promise.reject(err);
    });
    Collections.modifyInitState({
      ...Collections.getInitState,
      gaveConsent: true,
    });
    await Collections.setApiMilestone("Credentials");
    Collections.setCurrentComponent("Credentials");

    return Promise.resolve();
  }
}
