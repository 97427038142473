import _Vue from "vue";
import axios from "axios";
import { configExport } from "@/config/config";

export const axiosInstance = axios.create({
  baseURL: "https://localhost:44360",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-KEY": configExport.API_KEY,
  },
});
// console.log("configExport: ", configExport);
axiosInstance.interceptors.request.use((restConfig) => {
  return restConfig;
});

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$axiosInstance = axiosInstance;
  },
};
