import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "@/plugins/axios";
import config from "@/config/config";

Vue.config.productionTip = false;
Vue.use(config);
Vue.use(axios);

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
// console.log("Testing this.$config: ", vue.$config);
