
import { Component, Vue, Watch } from "vue-property-decorator";
import Collections, {
  challenge,
  dataProvider,
} from "@/store/modules/collections";
import moment from "moment";
@Component
export default class OTP extends Vue {
  loading = false;
  tokenPasswordPrompt = false;
  otp: string | null = null;
  valid = false;
  currentTime = moment().valueOf();
  timeStarted: number | null = null;
  timeLimit = 150;
  timerInterval: number | null = null;
  // equals to timestamp on challenge
  checkTimeOnMounted(): void {
    this.timeStarted = moment(
      this.getChallenge?.timestamp,
      "DD-MMM-YYYY HH:mm:ss.SSSZ"
    )
      .toDate()
      .valueOf();
    if (this.getChallenge?.timeout) {
      this.timeLimit = this.getChallenge.timeout / 1000;
    }
  }
  get getDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }
  mounted(): void {
    this.checkTimeOnMounted();
    this.startTimer();
    const form: any = this.$refs.otp;
    form.validate();
  }
  requestCancel(): void {
    Collections.setCurrentComponent("Cancel");
  }
  startTimer(): void {
    this.timerInterval = setInterval(
      () => (this.currentTime = moment().valueOf()),
      1000
    );
  }

  get getChallenge(): challenge | null {
    return Collections.getChallenge;
  }
  rules = {};
  get getSelectedDataProvider(): dataProvider | null {
    return Collections.getSelectedDataProvider;
  }
  async submitOTP(): Promise<void> {
    try {
      this.loading = true;
      if (this.valid == true && this.otp != null) {
        return await Collections.submitChallenge({
          str: this.otp,
          successMsg: this.getSelectedDataProvider?.name.includes("capitec")
            ? "Token password entered. "
            : "OTP entered",
        });
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  onTimesUp(): void {
    if (this.timerInterval != null) {
      clearInterval(this.timerInterval);
    }
    Collections.setAuthMessage("Timed out waiting for user input.");
    Collections.setCurrentComponent("Timeout");
  }
  get otpRules(): ((value: string) => string | boolean)[] {
    const rules = {
      required: (value: string) => !!value || "Required.",
      counter: (value: string) => {
        if (
          this.getChallenge?.details &&
          value.length != this.getChallenge?.details.length
        ) {
          return `${this.getChallenge?.details.length} characters required`;
        }
        return true;
      },
      type: (value: string) => {
        if (
          this.getChallenge?.details &&
          this.getChallenge?.details.type == "numeric"
        ) {
          const pattern = /^[0-9]+$/;
          return (
            pattern.test(value) ||
            `${this.getChallenge?.details.type} characters Required`
          );
        }
        return true;
      },
    };
    return [rules.required, rules.type, rules.counter];
  }
  get formattedTimeLeft(): string {
    const timeLeft = this.timeLeft;
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    let convertedMin = minutes.toString();
    let convertedSec = seconds.toString();

    if (minutes < 10) {
      convertedMin = `0${minutes}`;
    }

    if (seconds < 10) {
      convertedSec = `0${seconds}`;
    }

    return `${convertedMin}:${convertedSec}`;
  }
  get timeLeft(): number {
    if (this.timeStarted != null && !this.isPaused) {
      const time =
        this.timeLimit -
        Math.floor((this.currentTime - this.timeStarted) / 1000);
      if (time <= 0) {
        return 0;
      } else {
        return time;
      }
    }
    return 99;
  }
  @Watch("timeLeft", {
    immediate: true,
  })
  onTimeLeft(val: number): void {
    // console.log("Changing Time :", val);
    if (val <= 0) {
      this.onTimesUp();
    }
  }

  get formattedPauseTimeLeft(): string {
    const timeLeft = this.pauseTimeLeft();
    const minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;

    /* if (seconds < 10) {
      seconds = `0${seconds}`;
    } */

    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }

  pauseTimeLeft(): number {
    if (this.isPaused && this.getChallenge && this.timeStarted) {
      return Math.ceil(
        (this.timeStarted + this.getChallenge.pauseTime - this.currentTime) /
          1000
      );
    }
    return -1;
  }
  get isPaused() {
    if (this.getChallenge) {
      if (!this.getChallenge.pauseTime) return false;
      if (this.timeStarted) {
        return (
          this.currentTime < this.timeStarted + this.getChallenge.pauseTime
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
