import { render, staticRenderFns } from "./OTP.vue?vue&type=template&id=6fc018d6&scoped=true&"
import script from "./OTP.vue?vue&type=script&lang=ts&"
export * from "./OTP.vue?vue&type=script&lang=ts&"
import style0 from "./OTP.vue?vue&type=style&index=0&id=6fc018d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc018d6",
  null
  
)

export default component.exports