
import { Component, Vue } from "vue-property-decorator";
import Collections, { auth, collection, lastAuthStatus, reason } from "@/store/modules/collections";

@Component
export default class Cancelled extends Vue {
  get getCollection(): collection | null {
    return Collections.getCollection;
  }
  get getLastStatus(): lastAuthStatus | null {
    return Collections.authenticator.lastStatus;
  }
  redirectToCompanySite(): void {
    // let temp = `status=${this.getLastStatus?.code}`;
    window.location.href = "https://www.finchoice.mobi/Documents/DocUploader";
    // if (this.getCollection?.redirectUrl) {
    //   if (
    //     this.getCollection?.redirectUrl.includes("http") ||
    //     this.getCollection?.redirectUrl.includes("https")
    //   ) {
    //     if (this.getCollection?.redirectUrl.includes("?")) {
    //       temp = `&${temp}`;
    //     } else {
    //       temp = `?${temp}`;
    //     }
    //     window.location.href = this.getCollection?.redirectUrl + temp;
    //   } else {
    //     // invalid redirect url
    //     window.location.href = "http://www.truid.co.za" + temp;
    //   }
    // }
  }
}
