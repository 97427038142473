
import { Component, Vue } from "vue-property-decorator";
import Collections, { collection, storedConsent, dataProvider } from "@/store/modules/collections";

@Component
export default class PreConsent extends Vue {
  get getDataProviders(): dataProvider[] {
    return Collections.getDataProviders;
  }
  checkUrl(name: string): string {
    if (this.getDataProviders != undefined) {
      var temp = this.getDataProviders.filter((bank: dataProvider) => bank.code == name)[0];
      if (temp != undefined) {
        return temp.secondaryLogoUrl;
      }
    }
    return "";
  }
  async submitConsent(item: storedConsent): Promise<void> {
    await Collections.submitPreConsent(item).catch(err => {
      // console.log("Submit consent error: ", err);
      return Promise.reject(err);
    });
    Collections.modifyInitState({
      ...Collections.getInitState,
      hasSelectedConsent: true,
    });

    return Promise.resolve();
  }

  get getServiceText(): string {
    return Collections.getServiceText;
  }
  get getCollection(): collection | null {
    return Collections.getCollection;
  }
  get getName(): string | undefined {
    return this.getCollection?.name.split(" ")[0];
  }
  get getConsents(): storedConsent[] {
    return Collections.getConsents;
  }
}
