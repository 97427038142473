
import { Component, Vue } from "vue-property-decorator";
import Collections, { collection } from "@/store/modules/collections";
import Authenticating from "@/components/Authenticating.vue";
import AppAuth from "@/components/AppAuth.vue";
import AccountSelection from "@/components/AccountSelection.vue";
import Surephrase from "@/components/Surephrase.vue";
import OTP from "@/components/OTP.vue";
import QRCode from "@/components/QRCode.vue";


@Component({
  components: {
    Authenticating,
    AppAuth,
    AccountSelection,
    Surephrase,
    OTP,
    QRCode
  },
})
export default class Authenticator extends Vue {
  get getCurrentAuthComp(): string {
    return Collections.getAuthenticator.component;
  }
  mounted(): void {
    Collections.setAuthComp("Authenticating");
  }
}
