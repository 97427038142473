import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#0A2489",
        secondary: "#555555",
        accent: "#0A2489",
        error: "#FF4848",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: colors.grey.darken2,
        toolbar: "#0A1F8F",
        finchoice: "#283277"
      },
    },
  },
});
